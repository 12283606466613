// /src/pages/index.js
import React from "react"
import Layout from "../components/layout"
import Hero from "../components/hero"
import Contact from "../components/contact"
import Service from "../components/service"
import HeadLabel from "../components/head-label"
import PostLink from "../components/post-link"
import Button from "../components/button"
import { graphql } from "gatsby"
import Seo from "../components/seo"


export default function Index({ data }) {
  const edges = data.allContentfulNews.edges
  return (
    <Layout>
      <Seo
        title="TOP | 株式会社GoodTe | グッテ"
        description="株式会社GoodTeは病気を抱えながら生きる人が充実した人生が送れる社会を実現します。"
        ogpSiteName="TOP | 株式会社GoodTeコーポレートサイト"
      />
      <Hero
        message1={"It’s our home “GoodTe”"}
        bgClass={"bg-[url(../images/fv.png)]"}
      />

      <div className="container mx-auto">
        <div className="m-5 typography">
          <div className="text-xl text-center py-6">
            “つながり”を通して
            <br className=" md:hidden" />
            病気とともに歩む人の
            <br className=" md:hidden" />
            人生を豊かにする
          </div>
          <div className="mt-3 max-w-4xl mx-auto">
            グッテ(GoodTe)は、オンラインで患者と他の患者、専門家、情報などを繋げることで、現在の医療システムではなかなかカバーすることのできない患者さんの生活面の悩みを解決し、病気を抱えながら生きる人が充実した人生が送れる社会を実現します。
          </div>
        </div>

        <div className="my-20 max-w-4xl mx-auto">
          <HeadLabel label={"ニュース"} />

          {edges.map(edge => (
            <PostLink key={edge.node.slug} post={edge.node} />
          ))}
          <Button label={"全て見る"} link={"/news"} external={false} />
        </div>

        <div className="max-w-4xl mx-auto">
          <HeadLabel label={"サービス"} />
          <div className="p-5 mb-5">
            <div className="mx-auto flex flex-wrap justify-center max-w-3xl text-center">
              <Service
                title={"Gコミュニティ"}
                description={
                  "潰瘍性大腸炎・クローン病などの炎症性腸疾患患者さんが他の患者さんや専門家に医療や生活の不安や悩みを相談できる匿名・無料のオンラインコミュニティ"
                }
                label={"サービスサイトへ"}
                url={"/service/#gcom"}
                column={2}
              />

              <Service
                title={"グッテレシピ"}
                description={
                  "患者さんと作るお腹に優しいレシピサイト。IBD(潰瘍性大腸炎/クローン病)/IBS(過敏性腸症候群)患者さんやご家族が実際に作ったレシピを体調に合わせて検索できます。また、松本大学藤岡研究室よりIBD患者さん向けレシピをご提供いただき掲載しています。"
                }
                label={"サービスサイトへ"}
                url={"/service/#gcom"}
                column={2}
              />

              <Service
                title={"お腹にやさしいお菓子『やさしいひとくち』"}
                description={
                  "明治アクセラレータープログラムの支援を受け、患者さんとともに「お腹にやさしいｘリッチな味わい」にとことんこだわって作ったお菓子です。"
                }
                label={"サービスサイトへ"}
                url={"/service/#gcom"}
                column={2}
              />

              <Service
                title={"企業向けIBD患者さん調査、イベント企画・実行支援"}
                description={
                  "IBD患者さんのニーズ把握等を目的とした定性定量調査の実施、イベント企画・実行を支援"
                }
                label={"過去調査を見る"}
                url={"/service/#research"}
                column={2}
              />

              <Service
                title={"企業・病院向けサービス"}
                description={
                  "デジタルヘルス事業開発、デジタルマーケティング施策立案・実行を支援"
                }
                column={2}
              />
            </div>
          </div>

          <HeadLabel label={"ブログ"} />
          <div className="p-5 mx-auto max-w-4xl text-center">
            <Service
              title={"グッテnote"}
              description={
                "IBDに限らずさまざまな疾患の方向けの食事・就労に関する情報発信ブログ"
              }
              label={"noteを見る"}
              url={"https://note.com/goodte"}
              column={1}
              external={true}
            />
          </div>
        </div>
      </div>

      <Contact />
    </Layout>
  )
}

export const query = graphql`
  {
    allContentfulNews(limit: 3, sort: { fields: date, order: DESC }) {
      edges {
        node {
          title
          date(formatString: "YYYY年MM月DD日")
          link
          slug
          createdAt(formatString: "YYYY年MM月DD日")
          metadata {
            tags {
              name
            }
          }
        }
      }
    }
  }
`

// image {
//   title
//   file {
//     url
//   }
//   gatsbyImageData(formats: AUTO, width: 200)
// }
