// src/components/hero.js
import React from "react"

export default function Hero({ message1, message2, textSize, bgClass }) {
  const size = textSize != null ? textSize : "text-4xl"

  return (
    <div className="hero">
      <div className="w-full mx-auto m-0">
        <div
          className={
            `h-72 lg:h-screen flex justify-center items-center p-3 text-center bg-cover bg-center ` +
            bgClass
          }
        >
          <div className={` bg-white/50 animate-fade-in-down ` + size}>
            <h1 className="text-gray-700 py-3 tracking-wide leading-tight">
              {message1}
              <span className="inline-block">{message2}</span>
            </h1>
          </div>
        </div>
      </div>
    </div>
  )
}
