import React from "react"
//import { GatsbyImage, getImage } from "gatsby-plugin-image"

export default function PostLink({ post }) {
  const { title, date, link } = post
  //const description = post.description.description
  const tag = post.metadata.tags[0].name
  //const image = getImage(post.image)

  return (
    <div className="mt-5 mx-5 pb-3 border-b border-gray-400">
      {/* <div>
        {post.image === null ? (
          ""
        ) : (
          <GatsbyImage image={image} alt={post.image.title} />
        )}
      </div> */}
      <div className="mb-2 py-1">
        <span className="text-center px-3 mr-3 rounded-full text-[#33CCFF] border-x border-y border-[#33CCFF] inline-block w-24 font-medium">
          {tag}
        </span>
        <span className="text-[#33CCFF] font-medium">{date}</span>
      </div>
      <div className="typography">{title}</div>

      {link === null ? (
        ""
      ) : (
        <div className="mt-2 font-semibold">
          <a href={link} target="_blank" rel="noreferrer noopener">
            詳しくはこちら →
          </a>
        </div>
      )}
    </div>
  )
}
