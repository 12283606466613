// src/components/header.js
import React from "react"

//import { Link } from "gatsby"
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
// import {
//   faUserDoctor,
//   faHeart,
//   faUsers,
//   faHouseChimneyUser,
// } from "@fortawesome/free-solid-svg-icons"
//import { StaticImage } from "gatsby-plugin-image"
import Button from "./button"

export default function Service({
  title,
  description,
  label,
  url,
  column,
  external = false,
}) {
  return (
    <div className={`sm:w-1/${column}  mb-10 px-4`}>
      <h2 className="text-2xl font-medium mt-6 mb-3">{title}</h2>
      <p className="leading-relaxed text-base">{description}</p>
      {url != null ? (
        <Button label={label} link={url} external={external} />
      ) : (
        ""
      )}
    </div>
  )
}
