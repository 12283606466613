import { Link } from "gatsby"
import React from "react"

export default function Button({ link, label, external }) {
  return (
    <div className="flex text-white justify-center pb-6">
      {external ? (
        <a href={link} target="_blank" rel="noreferrer noopener">
          {GetButton(label)}
        </a>
      ) : (
        <Link to={link}>{GetButton(label)}</Link>
      )}
    </div>
  )
}

function GetButton(label) {
  return (
    <button className="bg-[#3b5] border-0 mt-4 py-2 px-8 focus:outline-none hover:bg-[#1f7434] rounded-full w-48">
      {label}
    </button>
  )
}
